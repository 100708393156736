import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {AuthorisationService, LookupService, NotificationService} from 'webui-library';

@Component({
  selector: 'cristalise-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  agent: string;
  pwd: string;

  constructor(
    private authService: AuthorisationService,
    private lookup: LookupService,
    private router: Router,
    private notification: NotificationService
  ) {
  }

  ngOnInit() {
    this.notification.clear();
  }

  onClick() {
    this.callLogin();
  }

  onPasswordEnter() {
    this.callLogin();
  }

  callLogin() {

    this.authService.postLogin(this.agent, this.pwd).subscribe(res => {
      if (res['Login'] && res['Login'].uuid) {
        sessionStorage.setItem('agentUUID', res['Login'].uuid);
      }
      this.authService.emitLoggedInUser(this.agent);
      this.authService.emitUserLoggedInStatus(true);
      this.authService.setLogedIn(this.agent);
      const redirectUrl = this.authService.redirectUrl ? this.authService.redirectUrl : '/items';
      this.router.navigateByUrl(redirectUrl);
    }, (err: any) => {
      // TODO add the error message coming from cristal server
      this.notification.add({
        severity: 'warn',
        detail: 'Incorrect username or password'
      });
      // throw err;
    });


  }
}
