import {Component, Input} from '@angular/core';
import {MenuItem} from 'primeng/primeng';

import {AppComponent} from './app.component';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  /* tslint:disable:component-selector */
  selector: '[cristalise-submenu]',
  /* tslint:enable:component-selector */
  templateUrl: './app.submenu.component.html',
  animations: [
    trigger('children', [
      state('hidden', style({
        height: '0px'
      })),
      state('visible', style({
        height: '*'
      })),
      transition('visible => hidden', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('hidden => visible', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class AppSubMenuComponent {

  @Input() item: MenuItem;

  @Input() root: boolean;

  @Input() visible: boolean;

  activeIndex: number;

  constructor(public app: AppComponent) {
  }

  itemClick(event: Event, item: MenuItem, index: number) {
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    this.activeIndex = (this.activeIndex === index) ? null : index;

    // execute command
    if (item.command) {
      item.command({originalEvent: event, item: item});
    }

    // prevent hash change
    if (item.items || (!item.url && !item.routerLink)) {
      event.preventDefault();
    }

    if (!item.items) {
      this.app.menuActiveMobile = false;
    }
  }

  isActive(index: number): boolean {
    return this.activeIndex === index;
  }
}
