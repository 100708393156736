export const environment = {
  production: false,
  serverBaseUrl: '',
  debug: false,
  cristalDetailsOn: false,
  viewPointDetailsOn: false,
  showExport: false,
  showDynamicButtons: false,
  project: 'MIA Environments',
  version: '1.0-SNAPSHOT',
  sessionTimeout: 15 * 60, // seconds
  convertTimezone: false,
  defaultDateFormat: 'YYYY-MMM-DD',
  defaultDateTimeFormat: 'YYYY-MMM-DD HH:mm:ss UTCZ',
  isConsoleLogsDisabled: false,
  reportingDBItems: [],
  reportingDBCollections: [],
  itemListRefreshInterval: 5000
};
