import {Component, OnInit, HostListener} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {AuthorisationService, ItemService, LookupService} from 'webui-library';
import {Router} from '@angular/router';

@Component({
  selector: 'cristalise-frame-user',
  templateUrl: './frame-user.component.html',
  styleUrls: ['./frame-user.component.css'],
  animations: [
    trigger('submenu', [
      state('hidden', style({
        height: '0px'
      })),
      state('visible', style({
        height: '*'
      })),
      transition('visible => hidden', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('hidden => visible', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class FrameUserComponent implements OnInit {

  public profileActive: boolean;
  public userId: string;
  public userRole: string;
  isLoggedIn = false;

  constructor(
    private itemService: ItemService,
    private lookupService: LookupService,
    private authorisationService: AuthorisationService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.authorisationService.loggedInUser.subscribe(
      user => {
        this.userId = user;
      });

    this.authorisationService.isUserLoggedIn.subscribe(
      isUserLoggedIn => {
        this.isLoggedIn = isUserLoggedIn;

        this.authorisationService.getRolesQuery(sessionStorage.getItem('agentUUID')).subscribe(roles => {
          console.log('frame-user.components.ts > ngOnInit > getRolesQuery > roles : ', roles);
          const firstRole = Object.keys(roles)[0];
          sessionStorage.setItem('roles', firstRole);
          this.userRole = firstRole; // To be used in the template
        }, (err: any) => {
          console.error('frame-user.components.ts > ngOnInit > getRolesQuery > err : ', err);
        });


      }
    );
    
    


  }

  toggleProfile(event: Event) {
    this.profileActive = !this.profileActive;
    event.preventDefault();
  }

  onLogout() {
    this.authorisationService.setLogedOut();
    this.authorisationService.emitUserLoggedInStatus(false);
    // this.messageService.add({ severity: 'warn', detail: 'You have successfully logged out!' });
    this.router.navigate(['/login']);
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: Event) {
    this.authorisationService.setLogedOut('windowClose');
    this.authorisationService.emitUserLoggedInStatus(false);
  }
}
