import { HttpErrorResponse } from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/primeng';
import {GlobalSettingsService, LookupService, MenuItemsService, NotificationService} from 'webui-library';


@Component({
  selector: 'cristalise-menu',
  template: `
    <div class="menu">
      <ul cristalise-submenu [item]="model" root="true"></ul>
    </div>
  `
})
export class AppMenuComponent implements OnInit {
  model: MenuItem[];

  constructor(
    private lookup: LookupService,
    private notification: NotificationService,
    private settings: GlobalSettingsService,
    private menuItems: MenuItemsService
  ) { }

  ngOnInit() {
    this.initRootMenu();
  }

  initRootMenu() {
    this.model = [
      {
        label : "Environments",
        icon : "fa-sitemap",
        routerLink : [ "env/Environments" ]
      }, {
        label : "Releases",
        icon : "fa-stamp",
        routerLink : [ "env/Releases" ]
      }, {
        label : "Templates",
        icon : "fa-file-code",
        routerLink : [ "env/Templates" ]
      },
//      {
//        label: 'Descriptions',
//        icon: 'fa-pencil-ruler',
//        command: (ev) => this.menuItems.onClick(ev),
//        items: this.menuItems.getWorkflowItems() // FIXME: use new MenuItemsService method
//      },
//      {
//        label: 'Instances',
//        icon: 'fa-sitemap',
//        command: (ev) => this.menuItems.onClick(ev),
//        items: this.menuItems.getResourcesItems()
//      }
    ];

//    if (this.settings.isCristalDetailsOn()) {
//      this.model.push(
//        { label: 'Domain', icon: 'fa fa-folder', routerLink: ['/domain'], command: (event => { this.fetchSubmenu(event); }) },
//        { label: 'Role', icon: 'fa fa-users', routerLink: ['/role'] }
//      );
//    }
  }

  fetchSubmenu(event, getDomainItems?: boolean) {
    // We store path in routingLink, remove leading /domain
    let srvPath;
    if (getDomainItems) {
      srvPath = '/domain/spe';
    } else if (event.item) {
      srvPath = event.item.routerLink.toString().replace(/\/domain/, '');
    } else {
      srvPath = event.replace(/\/domain/, '');
    }

    this.lookup.getDomainChildren(srvPath).subscribe(
      data => {
        const items = [];
        // console.log(data);
        for (let i = 0; i < data.rows.length; i++) {
          // console.log('paths: ' + data.rows[i].path);
          if (data.rows[i].uuid == null) {
            items.push({
              'label': this.capitalize(data.rows[i].name),
              'routerLink': data.rows[i].path,
              'icon': 'fa fa-folder',
              'command': (ev?: any) => this.fetchSubmenu(ev)
            });
          }
        }
        if (0 < items.length) {
          event.item.items = items;
        }
      },
      (err: HttpErrorResponse) => {
        this.notification.httpError(err);
      }
    );
  }

  capitalize(menuItem: string): string {
    return menuItem.charAt(0).toUpperCase() + menuItem.slice(1);
  }
}
