import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer,
  ViewChild
} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {AuthorisationService, BreadcrumbsService, NotificationService} from 'webui-library';
import {Router} from '@angular/router';

import {DEFAULT_INTERRUPTSOURCES, Idle} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';

import {environment} from '../environments/environment';

declare var jQuery: any;

@Component({
  selector: 'cristalise-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    trigger('submenu', [
      state('hidden', style({
        height: '0px'
      })),
      state('visible', style({
        height: '*'
      })),
      transition('visible => hidden', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('hidden => visible', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

  public menuInactiveDesktop: boolean;

  public menuActiveMobile: boolean;

  public topMenuActive: boolean;

  public topMenuLeaving: boolean;

  @ViewChild('scroller') public scrollerViewChild: ElementRef;

  public scroller: HTMLDivElement;

  documentClickListener: Function;

  menuClick: boolean;

  topMenuButtonClick: boolean;

  searchValue = '';

  isLoggedIn = false;

  timeoutState = '';
  timeoutStateExplanation = 'Take note of any unsaved data, and click here or press ESC key to continue.';
  timedOut = JSON.parse(sessionStorage.getItem('timedOut')) || false;

  constructor(
    public renderer: Renderer,
    private router: Router,
    private authService: AuthorisationService,
    private breadcrumbs: BreadcrumbsService,
    private notification: NotificationService,
    private idle: Idle
  ) {
    this.manageTimer();
  }

  private manageTimer() {
    if (environment.sessionTimeout) {
      this.idle.setIdle(environment.sessionTimeout);
      this.idle.setTimeout(1); // timeout timer only started after idle run out
    } else {
      // @ng-idle/core defaults: 20 mins for idle time and 30 sec for timeout time
    }

    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onTimeout.subscribe(() => {
      this.timeoutState = 'Session Expired';
      sessionStorage.setItem('timedOut', 'true');
      this.timedOut = true;

      this.idle.stop()

      this.authService.emitUserLoggedInStatus(false);
      this.authService.setLogedOut('timeout');
    });

    this.reset(this.timedOut);
  }


  reset(logout?: boolean) {
    this.timedOut = false;
    this.timeoutState = '';

    console.log('IDLE timeout:' + this.idle.getIdle())

    if (logout) {
      sessionStorage.removeItem('timedOut');
      this.router.navigate(['/login']);
    }
  }

  ngOnInit() {
    this.authService.isUserLoggedIn.subscribe(
      isUserLoggedIn => {
        this.isLoggedIn = isUserLoggedIn;
        this.menuInactiveDesktop = false;
        this.menuActiveMobile = true;

        if (isUserLoggedIn) {
          this.idle.watch();
        } else {
          this.idle.stop();
        }
      }
    );
  }

  ngAfterViewInit() {
    this.scroller = <HTMLDivElement>this.scrollerViewChild.nativeElement;

    // hides the overlay menu and top menu if outside is clicked
    this.documentClickListener = this.renderer.listenGlobal('body', 'click', (event) => {
      if (!this.isDesktop()) {
        if (!this.menuClick) {
          this.menuActiveMobile = false;
        }

        if (!this.topMenuButtonClick) {
          this.hideTopMenu();
        }
      }

      this.menuClick = false;
      this.topMenuButtonClick = false;
    });
  }

  toggleMenu(event: Event) {
    this.menuClick = true;
    if (this.isDesktop()) {
      this.menuInactiveDesktop = !this.menuInactiveDesktop;
      if (this.menuInactiveDesktop) {
        this.menuActiveMobile = false;
      }
    } else {
      this.menuActiveMobile = !this.menuActiveMobile;
      if (this.menuActiveMobile) {
        this.menuInactiveDesktop = false;
      }
    }

    if (this.topMenuActive) {
      this.hideTopMenu();
    }

    event.preventDefault();
  }

  toggleTopMenu(event: Event) {
    this.topMenuButtonClick = true;
    this.menuActiveMobile = false;

    if (this.topMenuActive) {
      this.hideTopMenu();
    } else {
      this.topMenuActive = true;
    }

    event.preventDefault();
  }

  hideTopMenu() {
    this.topMenuLeaving = true;
    setTimeout(() => {
      this.topMenuActive = false;
      this.topMenuLeaving = false;
    }, 500);
  }

  onMenuClick() {
    this.menuClick = true;

    setTimeout(() => {
      jQuery(this.scroller).nanoScroller();
    }, 600);
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  onSearchClick() {
    this.topMenuButtonClick = true;
  }

  onSearch() {
    if (this.searchValue) {
      this.breadcrumbs.reset();
      this.router.navigate(['/search', this.searchValue]);
      console.log(this.searchValue);
    }
  }

  onLogout() {
    this.authService.setLogedOut();
    this.authService.emitUserLoggedInStatus(false);
    this.router.navigate(['/login']);
  }

  isLoggedInFunction() {
    const loggedIn = this.authService.isLogedIn();

    if (!loggedIn) {
      this.menuInactiveDesktop = true;
      this.menuActiveMobile = false;
    }

    return loggedIn;
  }

  ngOnDestroy() {
    if (this.documentClickListener) {
      this.documentClickListener();
    }
  }

  onClickDisappear() {
    this.notification.clear();
  }

  getProject() {
    return environment.project ? environment.project : 'UNDEFINED';
  }

  getVersion() {
    return environment.version ? environment.version : 'VERSION UNDEFINED';
  }

  // Handle key press
  @HostListener('document:keydown', ['$event']) onkeyup(event: KeyboardEvent) {
    if (event.key === 'Escape' && this.timedOut) {
      this.reset(true);
    }
  }
}
