import {RouterModule, Routes} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';

import {AuthorisationGuard, ItemCardsComponent, ItemDetailsComponent, ItemListComponent, RoleTreeComponent} from 'webui-library';
import {LoginComponent} from './admin/login/login.component';
import { DomainListComponent } from './admin/domain-list/domain-list.component';

export const routes: Routes = [
  {path: '', redirectTo: 'login', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {path: 'role', component: RoleTreeComponent, canActivate: [AuthorisationGuard]},
  {path: 'domain',
    children: [{
      path: 'desc',
      children: [
        {path: 'ActivityDesc',children: [{path: '**', component: DomainListComponent}]},
        {path: 'PropertyDesc',children: [{path: '**', component: DomainListComponent}]},
        {path: 'Schema',      children: [{path: '**', component: DomainListComponent}]},
        {path: 'Script',      children: [{path: '**', component: DomainListComponent}]},
        {path: 'StateMachine',children: [{path: '**', component: DomainListComponent}]},
        {path: 'Module',      children: [{path: '**', component: DomainListComponent}]},
        {path: 'Dev',         children: [{path: '**', component: DomainListComponent}]},
//        {path: '**', component: DomainListComponent}
      ]
    }],
    canActivate: [AuthorisationGuard]
  },
  // This line bellow should replace all the domain specific routes above - currently 
  // it does not work becuause ItemListComponent does not refresh when clicking on menu items. 
  // {path: 'spe/:type', component: ItemListComponent, canActivate: [AuthorisationGuard]},
  {path: 'env/Services', component: ItemListComponent, canActivate: [AuthorisationGuard]},
  {path: 'env/Templates', component: ItemListComponent, canActivate: [AuthorisationGuard]},
  {path: 'env/Releases', component: ItemListComponent, canActivate: [AuthorisationGuard]},
  {path: 'env/Environments', component: ItemListComponent, canActivate: [AuthorisationGuard]},
  {path: 'env/:type/:name', component: ItemDetailsComponent, canActivate: [AuthorisationGuard]},
  {path: 'dashboard', component: ItemCardsComponent, canActivate: [AuthorisationGuard]},
  {path: '**', redirectTo: 'env/Environments'}
];

export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes, {enableTracing: false});

