import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpModule} from '@angular/http';
import {HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {DatePipe, HashLocationStrategy, LocationStrategy} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutes} from './app.routes';
// webui library
import {WebuiLibraryModule} from 'webui-library';
import {environment} from '../environments/environment';

import {
  AccordionModule,
  AutoCompleteModule,
  BreadcrumbModule,
  ButtonModule,
  CalendarModule,
  CardModule,
  CarouselModule,
  ChartModule,
  CheckboxModule,
  ChipsModule,
  CodeHighlighterModule,
  ColorPickerModule,
  ConfirmDialogModule,
  ContextMenuModule,
  DataGridModule,
  DataListModule,
  DataScrollerModule,
  DataTableModule,
  DialogModule,
  DragDropModule,
  DropdownModule,
  EditorModule,
  FieldsetModule,
  FileUploadModule,
  GalleriaModule,
  GMapModule,
  GrowlModule,
  InputMaskModule,
  InputSwitchModule,
  InputTextareaModule,
  InputTextModule,
  LightboxModule,
  ListboxModule,
  MegaMenuModule,
  MenubarModule,
  MenuModule,
  MessagesModule,
  MultiSelectModule,
  OrderListModule,
  OrganizationChartModule,
  OverlayPanelModule,
  PaginatorModule,
  PanelMenuModule,
  PanelModule,
  PasswordModule,
  PickListModule,
  ProgressBarModule,
  ProgressSpinnerModule,
  RadioButtonModule,
  RatingModule,
  ScheduleModule,
  SelectButtonModule,
  SharedModule,
  SidebarModule,
  SlideMenuModule,
  SliderModule,
  SpinnerModule,
  SplitButtonModule,
  StepsModule,
  TabMenuModule,
  TabViewModule,
  TerminalModule,
  TieredMenuModule,
  ToggleButtonModule,
  ToolbarModule,
  TooltipModule,
  TreeModule
} from 'primeng/primeng';
import {ConfirmationService} from 'primeng/api';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {TreeTableModule} from 'primeng/treetable';
import {TableModule} from 'primeng/table';

import {MessageService} from 'primeng/components/common/messageservice';

import {AppComponent} from './app.component';
import {AppMenuComponent} from './app.menu.component';
import {AppSubMenuComponent} from './app.submenu.component';

import {LoginComponent} from './admin/login/login.component';
import {DomainListComponent} from './admin/domain-list/domain-list.component';

import {DynamicFormsCoreModule} from '@ng-dynamic-forms/core';
import {DynamicFormsPrimeNGUIModule} from '@ng-dynamic-forms/ui-primeng';

import {MonacoEditorModule} from 'ngx-monaco-editor';
import {DragulaModule} from 'ng2-dragula';

import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';
import {MomentModule} from 'angular2-moment';
import {FrameUserComponent} from './admin/frame/frame-user/frame-user.component';
// Import Components used for reporting  (summary tables, charts, ...) :


// this includes the core NgIdleModule but includes keepalive providers for easy wireup
// optional, provides moment-style pipes for date formatting
@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutes,
    HttpModule,
    HttpClientModule,
    AccordionModule,
    AutoCompleteModule,
    BreadcrumbModule,
    ButtonModule,
    CardModule,
    CalendarModule,
    CarouselModule,
    ChartModule,
    ColorPickerModule,
    CheckboxModule,
    ChipsModule,
    CodeHighlighterModule,
    ConfirmDialogModule,
    SharedModule,
    ContextMenuModule,
    DataGridModule,
    DataListModule,
    DataScrollerModule,
    DataTableModule,
    DialogModule,
    DragDropModule,
    DropdownModule,
    EditorModule,
    FieldsetModule,
    FileUploadModule,
    GalleriaModule,
    GMapModule,
    GrowlModule,
    InputMaskModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    LightboxModule,
    ListboxModule,
    MegaMenuModule,
    MenuModule,
    MenubarModule,
    MessagesModule,
    MomentModule,
    MultiSelectModule,
    NgIdleKeepaliveModule.forRoot(),
    OrderListModule,
    OrganizationChartModule,
    OverlayPanelModule,
    PaginatorModule,
    PanelModule,
    PanelMenuModule,
    PasswordModule,
    PickListModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    RadioButtonModule,
    RatingModule,
    ScheduleModule,
    SelectButtonModule,
    SidebarModule,
    ScrollPanelModule,
    SlideMenuModule,
    SliderModule,
    SpinnerModule,
    SplitButtonModule,
    StepsModule,
    TabMenuModule,
    TabViewModule,
    TerminalModule,
    TieredMenuModule,
    ToggleButtonModule,
    ToolbarModule,
    TooltipModule,
    TreeModule,
    TreeTableModule,
    TableModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    DynamicFormsCoreModule.forRoot(),
    DynamicFormsPrimeNGUIModule,
    MonacoEditorModule,
    DragulaModule,
    WebuiLibraryModule.forRoot(environment)
  ],
  declarations: [
    AppComponent,
    AppMenuComponent,
    AppSubMenuComponent,
    LoginComponent,
    FrameUserComponent,
    DomainListComponent,
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    MessageService, ConfirmationService, DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

