import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ItemService, LookupService, UiConfigService, ToSpacedCamelCase } from 'webui-library'
import { switchMap, map } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { LazyLoadEvent } from 'primeng/api';

@Component({
  selector: 'webuilib-domain-list',
  templateUrl: './domain-list.component.html',
  styleUrls: ['./domain-list.component.css']
})
export class DomainListComponent implements OnInit {

  itemType: string;
  header: string;
  domainPath: string;
  uuid: string;
  itemName: string;
  selectedData: any;
  itemListConfig: any;

  totalRecords: number;
  itemData: any;
  loading = false;
  columns: any;

  loadTotalRecordSubject = new Subject<any>();
  loadItemSubject = new Subject<any>();

  constructor( protected router: Router,
    protected toSpacedCamelCase: ToSpacedCamelCase,
    private itemService: ItemService,
    protected uiConfigService: UiConfigService,
    private lookup: LookupService,
    private cdRef: ChangeDetectorRef,
    ) { }

  ngOnInit() {
    this.router.onSameUrlNavigation = 'reload';
    this.loading = true;
    this.domainPath = this.router.url;
    const item = this.toSpacedCamelCase.transform(this.domainPath.substr(this.domainPath.lastIndexOf('/') + 1));
    this.header = item;
    this.itemType = item.replace(' ', '');

    this.loadTotalRecordSubject.pipe(
      switchMap( () => {
        return this.itemService.getTotalDomainItems(this.domainPath);
      })
    ).subscribe( total => {
      this.totalRecords = total;
    });

    this.loadItemSubject.pipe(
      switchMap(params => {
        return this.loadItemDataList(params);
      })
    ).subscribe( () => {
      this.loading = false;
    });

    this.loadTotalRecordSubject.next();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  loadItemDataList(params: any): Observable<void> {
    this.loading = true;
    const getListConfig = this.uiConfigService.getListConfig(this.domainPath + '/itemList.json').pipe(
      map( itemListConfig => {
        this.columns = itemListConfig.column;
        this.itemListConfig = itemListConfig;
      })
    );
    return getListConfig.pipe(
      switchMap( () => {
        return this.lookup.getDomainChildren(this.domainPath, params);
      }),
      map( res => {
        this.itemData = [];
        res.rows.forEach(row => {
          const rowData = {};
          this.itemListConfig.column.forEach( column => {
            rowData[column.field] = row[column.field];
          });
          this.itemData.push(rowData);
        });
      })
    );
  }

  onLazyLoad($event: LazyLoadEvent) {
    this.loading = true;
    const params = {
      start: $event.first,
      batch: $event.rows
    };
    this.loadItemSubject.next(params);
  }


}
